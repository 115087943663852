import React, { Component } from "react";

class About extends Component {
  render() {
    return (
        <div>

            <div className="py-12 bg-white overflow-hidden">
            <div className="relative mx-auto pl-4 pr-4">
                <div className="relative">
                <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    About us
                </h3>
                <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
                    About us subtitle
                </p>
                </div>

                <div className="relative mt-12">
                <div className="relative">
                    <div className="mt-3 text-lg leading-7 text-gray-900">
                    About text
                    </div>
                </div>
                </div>


            </div>
            </div>
            
        </div>
    );
  }
}

export default About;
